import { PropertyDetails, searchPropertyDttQuery, searchPropertyQuery } from '@marriott/mi-groups-graphql';
import { useLazyQueryTransformer } from '../../hooks';

export const useSearchProperty = () => {
  return useLazyQueryTransformer<{ property: PropertyDetails }>({
    currentQuery: searchPropertyQuery,
    dttQuery: searchPropertyDttQuery,
    onTransform(data) {
      const { property } = data || {};
      const { descriptions } = property.basicInformation;
      (descriptions || []).forEach(item => {
        item.text = item.localizedText.translatedText;
      });
      return data;
    },
  });
};
