import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type DttState = {
  isDtt: boolean;
};

type DttActions = {
  setDttFlag: (isDtt: boolean) => void;
};

const initialState: DttState = {
  isDtt: false,
};

export const dttStore: StateCreator<DttState & DttActions> = set => {
  return {
    ...initialState,

    setDttFlag: (isDtt: boolean) => {
      set(prevState => {
        return {
          ...prevState,
          isDtt,
        };
      });
    },
  };
};

export const useDttStore = createAppStore(dttStore);
