import { FC, useEffect } from 'react';
import { GroupSearchFormProps } from '../GroupSearchForm.types';
import { SearchFormField } from './GroupSearchFormMobile.types';
import { GroupSearchFormMobileContainer } from './GroupSearchFormMobileContainer';
import { StyledGroupSearchFormMobile } from './GroupSearchFormMobile.styles';
import { useSearchFormStore } from '../../../store';
import { TextFormField } from '../../../molecules';
import { setCalendarOptions } from '../../../utils';

export const GroupSearchFormMobile: FC<GroupSearchFormProps> = props => {
  const { destination, dates, onSubmit, isSearchResultPage, searchResultsFormData, isSticky } = props;
  const { shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames } = dates;

  const { currentStep, setCurrentStep, defaultMobileView, setDefaultMobileView, persistedFormData } =
    useSearchFormStore();

  useEffect(() => {
    setCalendarOptions({
      shortWeekdayNames,
      longWeekdayNames,
      shortMonthNames,
      longMonthNames,
    });
  }, [shortWeekdayNames, longWeekdayNames, shortMonthNames, longMonthNames]);

  const destinationField = (
    <TextFormField
      label={destination.label}
      placeholder={destination.mobilePlaceholder}
      ariaLabel={destination.ariaLabel}
      className="m-input-field"
      onFocus={() => {
        setCurrentStep(SearchFormField.DESTINATION);
        setDefaultMobileView(false);
      }}
      value={persistedFormData.destination.description ?? searchResultsFormData?.destination?.description}
      getInputProps={() => ({
        readOnly: true,
      })}
    />
  );

  const datesField = (
    <TextFormField
      label={dates.label}
      placeholder={dates.mobilePlaceholder}
      ariaLabel={dates.ariaLabel}
      className="m-input-field"
      onFocus={() => setCurrentStep(SearchFormField.DATES)}
    />
  );

  const searchForm = (
    <div className="row">
      <div className="col-12 mb-4">{destinationField}</div>
      <div className="col-12">{datesField}</div>
    </div>
  );

  const searchFormCompact = (
    <div className="row">
      <div className="col-12">{destinationField}</div>
    </div>
  );

  return defaultMobileView || currentStep === SearchFormField.INITIAL ? (
    <StyledGroupSearchFormMobile isSearchResultPage={isSearchResultPage || isSticky}>
      {isSearchResultPage || isSticky ? searchFormCompact : searchForm}
    </StyledGroupSearchFormMobile>
  ) : (
    <GroupSearchFormMobileContainer
      labels={props}
      onSubmitSearch={onSubmit}
      isSearchResultPage={isSearchResultPage}
      searchResultsFormData={searchResultsFormData}
    />
  );
};
