/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import {
  getGroupRatesSearchRequestCode,
  createPlainFacetSelectionMap,
  getInitialSearchQueryInput,
  getUpdatedSearchFacetInput,
  isQuickGroupEligible,
  getSearchType,
} from '../utils';

import type {
  PageInfo,
  PropertySearchResults,
  PropertySearchQueryInput,
  PropertySearchFacetInput,
  PropertySearchFacet,
  PropertySearchTermFacetBucket,
  PropertySearchSortField,
  PropertySearchSortFieldDirection,
  PropertySearchInput,
  PropertyMediaGallery,
  GroupRatesPropertySearchQueryInput,
  GroupRatesPropertySearchResults,
} from '@marriott/mi-groups-graphql';
import { PropertyFacet } from '@marriott/mi-groups-graphql';
import { BrandItem, SearchType } from '../organisms/SearchResults/SearchResults.types';
import type { SearchQueryOptions } from '../utils/search';
import {
  SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS,
  SEARCH_RESULTS_PAGE_SIZE,
  QG_EXCLUDED_CODES,
  QG_EXCLUDED_CODES_ONLY_ATTENDEES,
} from '../constants';
import { FacetSelectionMap } from '../organisms/SearchResults/FilterBar/FilterBar.types';
import { getInitialSearchFacetInput, getInitialSearchSortInput } from '../utils/searchResults';

// Type definition for entire search result's state

type SearchResultsState = {
  loader: boolean;
  searchType: SearchType;
  searchQuery: PropertySearchQueryInput | GroupRatesPropertySearchQueryInput;
  availableFiltersQuery: PropertySearchQueryInput | GroupRatesPropertySearchQueryInput;
  // full search result's state
  searchResults: PropertySearchResults;
  // atomic search result's state
  properties: PropertySearchResults['edges'] | GroupRatesPropertySearchResults['edges'];
  propertyMedia: Record<string, PropertyMediaGallery>;
  facets: PropertySearchFacet[];
  // current facet selection
  facetsSelection: Partial<FacetSelectionMap>;
  // queryable facet filter
  facetsFilters: PropertySearchFacetInput;
  pageInfo: PageInfo;
  currentPage: number;
  showMapView: boolean;
  activePropertyId: string | number;
  quickViewPropertyId: string | number;
  isQuickGroupEnabled: boolean;
  isModalOpen: boolean;
  lastActiveElement: HTMLElement | null;
};

// Type definition for the actions that directly work with either the search result
// or, the query sent to search related calls

type SearchResultsAction = {
  setLoader: (loader: boolean) => void;
  updateSearchQuery: (data: SearchQueryOptions, locale: string) => void;
  setAvailableFiltersQuery: (data: PropertySearchQueryInput | GroupRatesPropertySearchQueryInput) => void;
  setSearchResults: (data: PropertySearchResults) => void;
  setPropertyMedia: (data: Record<string, PropertyMediaGallery>) => void;
  setQueryFacets: (data: PropertySearchFacetInput) => void;
  setShowMapView: () => void;
  setActivePropertyId: (propertId: string | number) => void;
  setQuickViewPropertyId: (propertyId: string | number) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

// Type definition for the actions that change search result page's state
// i.e. application of filters, sort and change of page

type FilterActions = {
  updateFacetByType: (type: PropertyFacet, payload: PropertySearchTermFacetBucket[] | BrandItem[]) => void;
  refreshFacetByType: (type: PropertyFacet, payload: PropertySearchTermFacetBucket[] | BrandItem[]) => void;
  updateSort: (sortBy: PropertySearchSortField, direction: PropertySearchSortFieldDirection) => void;
  updatePage: (offset: number) => void;
};

// Helpers to find the appropriate types (and their proper `type`) from a mixed data set
const isSearchTermFacetBucket = (
  item: PropertySearchTermFacetBucket | BrandItem
): item is PropertySearchTermFacetBucket => {
  return (item as PropertySearchTermFacetBucket)?.code !== undefined;
};

const isBrandItem = (item: PropertySearchTermFacetBucket | BrandItem): item is BrandItem => {
  return (item as BrandItem)?.brandTagId !== undefined;
};

const updateSearchFacets = (
  dynamicFacetType: PropertyFacet,
  payload: PropertySearchTermFacetBucket[] | BrandItem[],
  search: PropertySearchInput
) => {
  const facets = { ...search.facets };
  const terms = facets.terms ? [...facets.terms] : [];
  const ranges = facets.ranges ? [...facets.ranges] : [];
  const searchTermFacetBuckets = (payload as unknown as PropertySearchTermFacetBucket[]).filter(
    isSearchTermFacetBucket
  );
  const brandItems = (payload as unknown as BrandItem[]).filter(isBrandItem);

  if (dynamicFacetType === PropertyFacet.DISTANCE) {
    const rangeIndex = ranges.findIndex(range => range.dynamicFacetType === dynamicFacetType);
    const dimensions = searchTermFacetBuckets.length ? searchTermFacetBuckets.map(item => item.code) : [];
    if (rangeIndex !== -1) {
      ranges[rangeIndex] = { dynamicFacetType, dimensions, endpoints: SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS };
    } else {
      ranges.push({ dynamicFacetType, dimensions, endpoints: SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS });
    }
  } else {
    const termIndex = terms.findIndex(term => term.dynamicFacetType === dynamicFacetType);
    const dimensions = searchTermFacetBuckets.length
      ? searchTermFacetBuckets.map(item => item.code)
      : brandItems.length
      ? brandItems.map(brandItem => brandItem.brandTagId)
      : [];
    if (termIndex !== -1) {
      terms[termIndex] = { dynamicFacetType, dimensions };
    } else {
      terms.push({ dynamicFacetType, dimensions });
    }
  }

  facets.ranges = ranges;
  facets.terms = terms;
  search.facets = facets;
  return search;
};

// State initializers
const initialSearchResultsState: PropertySearchResults = {
  total: 0,
  pageInfo: {},
  edges: [],
  facets: [],
};

const initialState: SearchResultsState = {
  loader: true,
  searchType: SearchType.NONE,
  searchQuery: getInitialSearchQueryInput(),
  availableFiltersQuery: getInitialSearchQueryInput(),
  searchResults: { ...initialSearchResultsState },
  properties: initialSearchResultsState.edges,
  propertyMedia: {},
  facets: initialSearchResultsState.facets,
  facetsSelection: {},
  facetsFilters: {},
  pageInfo: { ...initialSearchResultsState.pageInfo },
  currentPage: 1,
  showMapView: false,
  activePropertyId: '',
  quickViewPropertyId: '',
  isQuickGroupEnabled: false,
  isModalOpen: false,
  lastActiveElement: null,
};

// The Zustand single store for search results with all the state and actions defined
export const SearchResultsStore: StateCreator<SearchResultsState & SearchResultsAction & FilterActions> = set => {
  return {
    ...initialState,
    setLoader: (isActive: boolean) => set(() => ({ loader: isActive })),

    updateSearchQuery: (payload: SearchQueryOptions, locale: string) => {
      const { destination, latitude, longitude, startDate, endDate, guestRooms, attendees, facets, sort, offset } =
        payload;

      const searchType = getSearchType(payload);

      const location =
        searchType === SearchType.GEOLOCATION
          ? { latitude: +(latitude || 0), longitude: +(longitude || 0) }
          : searchType === SearchType.DESTINATION
          ? { destination }
          : {};

      const numberOfRooms = +(guestRooms || 0);
      const numberOfAttendees = +(attendees || 0);

      let search = {};
      search = {
        ...location,
      };

      const isQuickGroup = isQuickGroupEligible(locale, numberOfRooms, numberOfAttendees, startDate, endDate);
      if (isQuickGroup) {
        search = {
          ...search,
          numberOfRooms,
          numberOfAttendees,
          startDate,
          endDate,
          excludedGroupLevelCodes: numberOfAttendees ? QG_EXCLUDED_CODES_ONLY_ATTENDEES : QG_EXCLUDED_CODES,
          groupId: `M0${Date.now()}`,
          requestCode: getGroupRatesSearchRequestCode(numberOfRooms, numberOfAttendees),
        };
      }

      const options = attendees ? { largestEventSpace: numberOfAttendees * 10 } : { numberOfGuestRooms: numberOfRooms };

      const allFacets = facets?.terms?.concat(facets.ranges ?? []);

      set(prevState => {
        const searchParams = {
          search: {
            ...search,
            options: { numberInParty: 1, ...options },
            facets: {
              ...(facets?.terms?.length || facets?.ranges?.length
                ? getUpdatedSearchFacetInput(facets)
                : getInitialSearchFacetInput()),
            },
          },
          ...(sort && sort.fields?.length ? { sort } : getInitialSearchSortInput()),
          ...(offset ? { offset } : { offset: 0 }),
        };

        return {
          searchType,
          searchQuery: {
            ...prevState.searchQuery,
            ...searchParams,
          },
          facetsSelection: createPlainFacetSelectionMap(allFacets),
          isQuickGroupEnabled: isQuickGroup,
        };
      });
    },

    setSearchResults: (payload: PropertySearchResults) =>
      set({
        searchResults: payload,
        properties: payload.edges,
        facets: payload.facets,
        pageInfo: payload.pageInfo,
        currentPage: payload.pageInfo?.currentOffset
          ? Math.floor(+payload.pageInfo.currentOffset / SEARCH_RESULTS_PAGE_SIZE) + 1
          : 1,
      }),

    setQueryFacets: (payload: PropertySearchFacetInput) => set({ facetsFilters: payload }),

    setAvailableFiltersQuery: (payload: PropertySearchQueryInput) => set({ availableFiltersQuery: payload }),

    updateFacetByType: (dynamicFacetType: PropertyFacet, payload: PropertySearchTermFacetBucket[] | BrandItem[]) => {
      set(prevState => {
        const search = updateSearchFacets(dynamicFacetType, payload, { ...prevState.searchQuery.search });

        return {
          searchQuery: {
            ...prevState.searchQuery,
            search: search,
            offset: 0,
          },
          facetsSelection: {
            ...prevState.facetsSelection,
            [dynamicFacetType]: payload,
          },
        };
      });
    },

    refreshFacetByType: (dynamicFacetType: PropertyFacet, payload: PropertySearchTermFacetBucket[] | BrandItem[]) => {
      set(prevState => {
        const search = updateSearchFacets(dynamicFacetType, payload, { ...prevState.availableFiltersQuery.search });

        return {
          availableFiltersQuery: {
            ...prevState.availableFiltersQuery,
            search: search,
            offset: 0,
          },
        };
      });
    },

    updateSort: (sortBy: PropertySearchSortField, direction: PropertySearchSortFieldDirection) => {
      set(prevState => {
        const { searchQuery } = prevState;
        const updatedSort = {
          // the toUpperCase keeps the sort working even if lowercase or mixed case comes from CMS, as long as the keys are correct
          fields: [{ field: sortBy, direction: direction.toUpperCase() as PropertySearchSortFieldDirection }],
        };
        return {
          searchQuery: {
            ...searchQuery,
            sort: updatedSort,
            offset: 0,
          },
        };
      });
    },

    updatePage: (offset: number) => {
      set(prevState => {
        const { searchQuery } = prevState;
        return {
          searchQuery: {
            ...searchQuery,
            offset: offset,
          },
        };
      });
    },

    setShowMapView: () => {
      set(prevState => {
        return {
          ...prevState,
          showMapView: !prevState.showMapView,
        };
      });
    },

    setIsModalOpen: (isModalOpen: boolean) => {
      set(prevState => {
        return {
          ...prevState,
          isModalOpen,
          lastActiveElement: isModalOpen ? (document?.activeElement as HTMLElement) : null,
        };
      });
    },

    setActivePropertyId: (propertyId: string | number) => {
      set(prevState => {
        return {
          ...prevState,
          activePropertyId: propertyId,
        };
      });
    },

    setQuickViewPropertyId: (propertyId: string | number) => {
      set(prevState => {
        return {
          ...prevState,
          quickViewPropertyId: propertyId,
        };
      });
    },

    setPropertyMedia: (payload: Record<string, PropertyMediaGallery>) => {
      set(prevState => {
        return {
          ...prevState,
          propertyMedia: payload,
        };
      });
    },
  };
};

export const useSearchResultsStore = createAppStore(SearchResultsStore);
