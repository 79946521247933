import { FC, useEffect, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Types, baseVariables } from '@marriott/mi-ui-library';
import { StyledGroupSearchFormDesktop } from './GroupSearchFormDesktop.styles';
import { GroupSearchFormProps } from '../GroupSearchForm.types';
import { GroupSearchFormData } from '../GroupSearchForm.types';
import { Destination } from '../Destination';
import { useLocaleStore, useSearchFormStore } from '../../../store';
import {
  getFormattedDateObject,
  isValidNumeric,
  validateAttendees,
  validateGuestRooms,
  getFormattedCalendarDate,
} from '../../../utils';
import { useFocusState, useMediaQuery } from '../../../hooks';
import { TextFormField, SingleSelectList, DatePickerDesktop } from '../../../molecules';

export const GroupSearchFormDesktop: FC<GroupSearchFormProps> = props => {
  const {
    destination,
    dates,
    eventType,
    guestRooms,
    attendees,
    ctas,
    isSearchResultPage,
    isSticky,
    searchResultsFormData,
    onSubmit,
  } = props;
  const { loader, setLoader, persistedFormData, setPersistedFormData } = useSearchFormStore();
  const { locale } = useLocaleStore();

  const isTablet = useMediaQuery(baseVariables.mediaQuery.md);
  const isDeskTop = useMediaQuery(baseVariables.mediaQuery.lg);

  const isTabletOnly = isTablet && !isDeskTop;
  const headers = {
    'accept-language': locale,
  };

  const dateProps = { ...dates, done: ctas['done'], doneAriaLabel: ctas['doneAriaLabel'] };

  const defaultEventType = searchResultsFormData
    ? [searchResultsFormData.eventType]
    : eventType.options.filter(option => option.selected === true);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm<GroupSearchFormData>({
    defaultValues: {
      destination: { description: '', placeId: '' },
      dates: '',
      startDate: '',
      endDate: '',
      eventType: { label: defaultEventType[0]?.label, code: defaultEventType[0]?.code || '' },
      guestRooms: '',
      attendees: '',
      flexibleDates: false,
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { handleFocus, handleBlur } = useFocusState({});

  const submitCallback = (data: GroupSearchFormData) => {
    setLoader(true);
    onSubmit(data);
    setPersistedFormData({
      ...data,
    });
  };

  const handleDatesChange = (dates: string, startDate: string, endDate: string, flexibleDates: boolean) => {
    setValue('dates', dates);
    setValue('startDate', startDate || '');
    setValue('endDate', endDate || '');
    setValue('flexibleDates', flexibleDates);

    // On date selection, storing the dd/mm/yyyy formatted date in persistedFormData
    setPersistedFormData({
      ...persistedFormData,
      dates,
      startDate: startDate ? getFormattedCalendarDate(startDate, 'hyphenatedDateWithMonthNoAndYear') : '',
      endDate: endDate ? getFormattedCalendarDate(endDate, 'hyphenatedDateWithMonthNoAndYear') : '',
      flexibleDates,
    });
  };

  const setSearchResultsFormData = (values: GroupSearchFormData) => {
    const { destination, guestRooms, attendees, flexibleDates } = values;
    setValue('destination', { ...destination });
    setValue('flexibleDates', flexibleDates);
    setValue('guestRooms', guestRooms);
    setValue('attendees', attendees);
  };

  useEffect(() => {
    handleDestinationChange(persistedFormData.destination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedFormData.destination]);

  useEffect(() => {
    if (isSearchResultPage && searchResultsFormData) {
      setSearchResultsFormData(searchResultsFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Once change in stickiness, selecting the previously selected Event type stored in persisted form data
  useEffect(() => {
    eventType.options.map(option => {
      if (option.code === persistedFormData.eventType.code) {
        option.selected = true;
      } else {
        option.selected = false;
      }
      return option;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSticky]);

  const handleDestinationChange = (destination: {
    description: string;
    placeId?: string;
    secondaryDescription?: string;
  }) => {
    setPersistedFormData({ ...persistedFormData, destination });
    setValue('destination', { ...destination });
    clearErrors('destination');
  };

  const handleKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    event.stopPropagation();
  };

  const destinationField = (
    <Controller
      name="destination"
      control={control}
      rules={{
        validate: value => !!(value && value.description) || destination.requiredError,
      }}
      render={({ field }) => (
        <Destination
          {...field}
          labels={destination}
          customHeaders={headers}
          currentValue={persistedFormData?.destination?.description}
          showErrorMessage={!!errors.destination?.message}
          destinationOnChange={handleDestinationChange}
        />
      )}
    />
  );

  const datesField = (
    <Controller
      name="dates"
      control={control}
      render={() => (
        <DatePickerDesktop
          dates={dateProps}
          align={isSearchResultPage || isSticky ? 'left' : 'right'}
          iconAriaLabel={`${ctas.clearLabel} ${dates.label}`}
          selectedStartDate={
            isSearchResultPage && searchResultsFormData?.startDate
              ? getFormattedDateObject(searchResultsFormData?.startDate)
              : persistedFormData.startDate
              ? getFormattedDateObject(persistedFormData?.startDate)
              : null
          }
          selectedEndDate={
            isSearchResultPage && searchResultsFormData?.endDate
              ? getFormattedDateObject(searchResultsFormData?.endDate)
              : persistedFormData.endDate
              ? getFormattedDateObject(persistedFormData?.endDate)
              : null
          }
          flexibleDates={
            (isSearchResultPage ? searchResultsFormData?.flexibleDates : persistedFormData?.flexibleDates) || false
          }
          onChange={handleDatesChange}
        />
      )}
    />
  );

  const eventTypeField = (
    <Controller
      name="eventType"
      control={control}
      render={({ field }) => (
        <SingleSelectList
          {...field}
          label={eventType.label}
          ariaLabel={eventType.ariaLabel}
          placeholder={eventType.select}
          eyebrowText={eventType.selectEventType}
          options={eventType.options}
          expandIconAriaLabel={`${eventType.label} ${eventType.expandIconAriaLabel}`}
          collapseIconAriaLabel={`${eventType.label} ${eventType.collapseIconAriaLabel}`}
          onChange={option => {
            const eventType = { label: option.label, code: option.code || '' };
            setPersistedFormData({
              ...persistedFormData,
              eventType,
            });
            return setValue('eventType', eventType);
          }}
        />
      )}
    />
  );

  const guestRoomsField = (
    <Controller
      name="guestRooms"
      control={control}
      rules={{
        validate: value =>
          validateGuestRooms(value, getValues('attendees'), guestRooms.requiredError, guestRooms.minCountError),
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={guestRooms.label}
          placeholder={guestRooms.placeholder}
          ariaLabel={guestRooms.ariaLabel}
          className="m-input-field"
          showIcon={!!field.value}
          iconClass={'icon icon-s icon-cancel'}
          iconAriaLabel={`${ctas.clearLabel} ${guestRooms.label}`}
          iconClickHandler={() => {
            field.onChange('');
            setPersistedFormData({ ...persistedFormData, guestRooms: '' });
            clearErrors('guestRooms');
          }}
          showErrorMessage={!!errors.guestRooms?.message}
          errorMessage={errors.guestRooms?.message}
          onChange={event => {
            if (!isValidNumeric(event.target.value)) return;
            field.onChange(event);
            setPersistedFormData({ ...persistedFormData, guestRooms: event.target.value });
            clearErrors('guestRooms');
          }}
          onFocus={() => handleFocus(field.name)}
          onBlur={() => handleBlur(field.name)}
          value={isSearchResultPage ? field.value : persistedFormData.guestRooms}
        />
      )}
    />
  );

  const attendeesField = (
    <Controller
      name="attendees"
      control={control}
      rules={{
        validate: value => validateAttendees(value, getValues('guestRooms'), attendees.requiredError),
      }}
      render={({ field }) => {
        const attendeesCount = isSearchResultPage ? field.value : persistedFormData.attendees;
        return (
          <TextFormField
            label={attendees.label}
            placeholder={attendees.placeholder}
            ariaLabel={attendees.ariaLabel}
            className="m-input-field"
            showHelperText={!!attendeesCount}
            helperText={`${attendees.helperText}: ${Math.floor(+attendeesCount * attendees.spaceMultiplier)}`}
            showIcon={!!field.value}
            iconClass={'icon icon-s icon-cancel'}
            iconAriaLabel={`${ctas.clearLabel} ${attendees.label}`}
            iconClickHandler={() => {
              field.onChange('');
              setPersistedFormData({ ...persistedFormData, attendees: '' });
              clearErrors('attendees');
            }}
            {...field}
            showErrorMessage={!!errors.attendees?.message}
            errorMessage={errors.attendees?.message}
            onChange={event => {
              if (!isValidNumeric(event.target.value)) return;
              field.onChange(event);
              setPersistedFormData({ ...persistedFormData, attendees: event.target.value });
              clearErrors('attendees');
            }}
            onFocus={() => handleFocus(field.name)}
            onBlur={() => handleBlur(field.name)}
            value={attendeesCount}
          />
        );
      }}
    />
  );

  const searchForm = (
    <Fragment>
      <div className="row pb-40">
        <div className="col-lg-1"></div>
        <div className="col-lg-5 col-md-6">{destinationField}</div>
        <div className="col-lg-5 col-md-6">{datesField}</div>
        <div className="col-lg-1"></div>
      </div>
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-4 col-md-3">{eventTypeField}</div>
        <div className="col-lg-2 col-md-3">{guestRoomsField}</div>
        <div className="col-lg-2 col-md-3">{attendeesField}</div>
        <div className="col-lg-2 col-md-3">
          <Button
            type={Types.ButtonTypeVariation.Submit}
            ariaLabel={ctas.findAriaLabel}
            className={`m-button-primary w-100 ${loader ? `m-button skeleton-loader` : ''}`}
            isDisabled={loader}
          >
            {ctas.find}
          </Button>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </Fragment>
  );

  const searchFormCompactDesktop = (
    <div className="row">
      <div className="col-lg-3 pl-0">{destinationField}</div>
      <div className="col-lg-3">{datesField}</div>
      <div className="col-md-2">{guestRoomsField}</div>
      <div className="col-md-2">{attendeesField}</div>
      <div className="col-md-2 pr-0">
        <Button
          type={Types.ButtonTypeVariation.Submit}
          ariaLabel={isSearchResultPage ? ctas.updateAriaLabel : ctas.findAriaLabel}
          className="m-button-secondary w-100"
        >
          {isSearchResultPage ? ctas.update : ctas.find}
        </Button>
      </div>
    </div>
  );

  const searchFormCompactTablet = (
    <Fragment>
      <div className="row">
        <div className="col-lg-6 col-md-6">{destinationField}</div>
        <div className="col-lg-6 col-md-6">{datesField}</div>
      </div>
      <div className="row mt-md-3">
        <div className="col-md-4">{guestRoomsField}</div>
        <div className="col-md-4">{attendeesField}</div>
        <div className="col-md-4">
          <Button
            type={Types.ButtonTypeVariation.Submit}
            ariaLabel={isSearchResultPage ? ctas.updateAriaLabel : ctas.findAriaLabel}
            className="m-button-secondary w-100"
          >
            {isSearchResultPage ? ctas.update : ctas.find}
          </Button>
        </div>
      </div>
    </Fragment>
  );

  return (
    <StyledGroupSearchFormDesktop isSearchResultPage={isSearchResultPage || isSticky}>
      <form onSubmit={handleSubmit(submitCallback)} onKeyUp={handleKeyEvent} onKeyDown={handleKeyEvent}>
        {!isSearchResultPage && !isSticky
          ? searchForm
          : isTabletOnly
          ? searchFormCompactTablet
          : searchFormCompactDesktop}
      </form>
    </StyledGroupSearchFormDesktop>
  );
};
