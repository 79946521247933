import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { font, mediaQuery, color } = baseVariables;

export const StyledPropertyHeader = styled.div<{ hasReviews: boolean }>`
  .loader {
    height: ${toRem(120)};
  }

  .property-header {
    padding: ${toRem(20)} 0;
    .brand-logo {
      margin-right: ${toRem(68)};
      font-weight: ${font.fontWeightRegular};
      .property-logo {
        max-width: ${toRem(140)};
        object-fit: contain;
      }
      .brand-logo-large {
        color: unset;
        &.brand-logo-XE {
          &:before {
            font-size: ${toRem(40)};
          }
        }
        &:hover {
          background-color: unset;
        }
      }
    }
    .property-details {
      padding-top: ${toRem(12)};
      justify-content: space-between;
      position: relative;
      .m-icon-text {
        color: unset;
      }
    }
    .contact-info {
      width: auto;
      position: initial;
      font-size: ${toRem(14)};
      line-height: ${toRem(20)};
      margin: 0 !important;
      .contact-number-container {
        color: unset;
        .contact-number {
          padding-left: ${toRem(4)};
        }
      }
      .contact-list {
        width: ${toRem(300)};
        left: ${({ hasReviews }) => (hasReviews ? 0 : 'auto')};
        right: 0;
        top: auto;
        .contact-option {
          p,
          a {
            font-size: ${toRem(14)};
            line-height: ${toRem(20)};
            color: ${color.base10};
          }
        }
      }
    }
    a,
    a:hover {
      font-weight: ${font.fontWeightRegular};
    }
  }

  @media ${mediaQuery.md} {
    .property-header {
      .property-details {
        justify-content: flex-start;
        column-gap: ${toRem(16)};
        position: initial;
      }
      .contact-info {
        font-size: ${toRem(12)};
        line-height: ${toRem(16)};
        position: relative;
        .contact-list {
          left: auto;
        }
      }
    }
  }

  @media ${mediaQuery.lg} {
    .property-header {
      .brand-logo {
        margin-right: ${toRem(80)};
      }
      .contact-info {
        position: relative;
        .contact-list {
          width: ${toRem(360)};
          left: auto;
        }
      }
    }
  }
`;
