import React, { useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { StyleSheetManager } from 'styled-components';
import Script from 'next/script';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import { useNextMiApolloClient, getDeployedEnvType } from '@marriott/mi-apollo-client-utils';
import { defineComponentMapping } from '../import-components';
import { CustomModelClient, canUseDOM } from '@marriott/mi-headless-utils';
import { ApolloProvider } from '@apollo/client';
import path from 'path';
import { MockApolloClient } from '../mocks/apolloClient';
import {
  ErrorHandlerAlert,
  useLocaleStore,
  useAuthStore,
  useMediaQueryStore,
  useHeaderFieldsStore,
  DEFAULT_LANG,
  useDttStore,
  isAllowedForLocale,
} from '@marriott/mi-groups-components';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import '../styles/styles.css';
import { baseVariables } from '@marriott/mi-ui-library';
path.resolve('./next.config.js');

declare global {
  interface Window {
    jQuery: unknown;
    $: unknown;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const mockUXL = process.env.NEXT_PUBLIC_API_MOCKING === 'true';

if (mockUXL) {
  require('../mocks');
}

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const MiApolloClient = useNextMiApolloClient([], getDeployedEnvType());

  const GOOGLE_MAP_API_KEY = process.env['GOOGLE_MAP_API_KEY'];

  const { Component, pageProps } = props;
  const {
    model,
    pagePath,
    isAuthorMode,
    currentLocale,
    isDTT,
    cookies,
    apolloEnvVars = {},
    serverENV,
    requestId,
    headersData,
  } = pageProps;

  const serverEnvVars = serverENV ? JSON.parse(serverENV) : {};
  const locale = currentLocale ? currentLocale.replace('_', '-') : DEFAULT_LANG;

  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;

  const configureRtlPlugin = (...args: unknown[]) => {
    return rtlPlugin(args[0] as number, args[1] as string);
  };

  const enableRtl = pageProps?.model?.rtlEnable === true;

  // load global styles on the basis of dir present in props
  if (enableRtl) {
    import('@marriott/global-styles/dist/marriot.global.rtl.css');
  } else {
    import('@marriott/global-styles/dist/marriot.global.css');
  }

  defineComponentMapping(allowedComponents, pagePath, isAuthorMode, currentLocale);

  const envVars = {
    ...apolloEnvVars,
    ...serverEnvVars,
  };

  useDttStore.getState().setDttFlag(isDTT);

  if (!Object.keys(useClientEnvVarsStore.getState().envVarsObject).length) {
    useClientEnvVarsStore.getState().setEnvVars(envVars);
  }

  if (!useLocaleStore.getState().locale) {
    useLocaleStore.getState().setLocale(locale);
  }

  if (canUseDOM) {
    useMediaQueryStore
      .getState()
      .setIsTabletAndAbove(window.innerWidth >= parseInt(baseVariables.mediaQuery.md.match(/\d+/)?.[0] as string));
  }

  useEffect(() => {
    useAuthStore.getState().setAuthCookie(cookies);
    useHeaderFieldsStore.getState().setRequestId(requestId);
    headersData.api && useHeaderFieldsStore.getState().setApiValue(headersData.api);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandlerAlert>
      <StyleSheetManager stylisPlugins={enableRtl ? [configureRtlPlugin] : []}>
        <ApolloProvider client={mockUXL ? MockApolloClient : MiApolloClient}>
          <Component {...pageProps} />
          {isAllowedForLocale(locale) && (
            <>
              <Script src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`} defer={true} />
              <Script src="https://unpkg.com/@googlemaps/markerwithlabel@1.0.3/dist/index.min.js" defer={true} />
            </>
          )}
        </ApolloProvider>
      </StyleSheetManager>
    </ErrorHandlerAlert>
  );
};

export default App;
