import {
  PropertySearchResults,
  // Search properties queries
  searchPropertiesByGeolocationQuery,
  searchPropertiesByGeolocationDttQuery,
  searchPropertiesByLocationQuery,
  searchPropertiesByLocationDttQuery,
  searchPropertiesByDestinationQuery,
  searchPropertiesByDestinationDttQuery,
  // Available filter queries
  getAvailableFiltersByGeolocationQuery,
  getAvailableFiltersByGeolocationDttQuery,
  getAvailableFiltersByLocationQuery,
  getAvailableFiltersByLocationDttQuery,
  getAvailableFiltersByDestinationQuery,
  getAvailableFiltersByDestinationDttQuery,
} from '@marriott/mi-groups-graphql';
import { useLazyQueryTransformer } from '../../hooks';

type SearchPropertiesByGeolocation = {
  searchPropertiesByGeolocation: PropertySearchResults;
};

type SearchPropertiesByLocation = {
  searchPropertiesByLocation: PropertySearchResults;
};

type SearchPropertiesByDestination = {
  searchPropertiesByDestination: PropertySearchResults;
};

// DTT types
type SearchPropertiesByGeolocationDtt = {
  search: { properties: { searchByGeolocation: PropertySearchResults } };
};

type SearchPropertiesByLocationDtt = {
  search: { properties: { searchByLocation: PropertySearchResults } };
};

type SearchPropertiesByDestinationDtt = {
  search: { properties: { searchByDestination: PropertySearchResults } };
};

export const useSearchPropertiesByGeolocation = (useFilters = false) => {
  return useLazyQueryTransformer<SearchPropertiesByGeolocation, SearchPropertiesByGeolocationDtt>({
    currentQuery: useFilters ? getAvailableFiltersByGeolocationQuery : searchPropertiesByGeolocationQuery,
    dttQuery: useFilters ? getAvailableFiltersByGeolocationDttQuery : searchPropertiesByGeolocationDttQuery,
    onTransform: data => {
      return {
        search: {
          properties: {
            searchByGeolocation: data.searchPropertiesByGeolocation,
          },
        },
      };
    },
  });
};

export const useSearchPropertiesByLocation = (useFilters = false) => {
  return useLazyQueryTransformer<SearchPropertiesByLocation, SearchPropertiesByLocationDtt>({
    currentQuery: useFilters ? getAvailableFiltersByLocationQuery : searchPropertiesByLocationQuery,
    dttQuery: useFilters ? getAvailableFiltersByLocationDttQuery : searchPropertiesByLocationDttQuery,
    onTransform: data => {
      return {
        search: {
          properties: {
            searchByLocation: data.searchPropertiesByLocation,
          },
        },
      };
    },
  });
};

export const useSearchPropertiesByDestination = (useFilters = false) => {
  return useLazyQueryTransformer<SearchPropertiesByDestination, SearchPropertiesByDestinationDtt>({
    currentQuery: useFilters ? getAvailableFiltersByDestinationQuery : searchPropertiesByDestinationQuery,
    dttQuery: useFilters ? getAvailableFiltersByDestinationDttQuery : searchPropertiesByDestinationDttQuery,
    onTransform: data => {
      return {
        search: {
          properties: {
            searchByDestination: data.searchPropertiesByDestination,
          },
        },
      };
    },
  });
};
