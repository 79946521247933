// --- Generics and Standard types --- //

export type Node<T> = {
  node: T;
};

export type Connection<T> = {
  edges: Node<T>[];
};

export type PageInfo = {
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  previousOffset?: number;
  currentOffset?: number;
  nextOffset?: number;
};

type SearchCenter = {
  address: string;
  latitude: string;
  longitude: string;
  name: string;
};

// -- Search Result: Result Types --//

// types for facets

export type PropertySearchFacet = {
  type: PropertySearchFacetType;
  buckets: PropertySearchTermFacetBucket[];
};

export type PropertySearchFacetType = {
  code: string;
  label: string;
  description: string;
  count: number;
};

export enum PropertyFacet {
  BRANDS = 'brands',
  POINTS = 'points',
  AMENITIES = 'amenities',
  PROPERTY_TYPES = 'property-types',
  PRICE = 'price',
  ACTIVITIES = 'activities-on-site',
  CITIES = 'cities',
  STATES = 'states',
  COUNTRIES = 'countries',
  DISTANCE = 'distance',
  HOTEL_SERVICE_TYPES = 'hotel-service-types',
  TRANSPORTATION_TYPES = 'transportation-types',
  LEISURE_REGIONS = 'leisure-region',
  MEETINGS_EVENTS = 'meetings-and-events',
  ALL_FILTERS = 'all-filters',
}

export type PropertySearchTermFacetBucket = {
  code: string;
  label: string;
  description: string;
  count: number;
  start?: string;
  end?: string;
  index?: number;
};

// type for hotel nodes
export type PropertySearchResultsInput = {
  total: number;
  facets: PropertySearchFacet[];
  pageInfo: PageInfo;
  searchCenter?: SearchCenter;
};

export type PropertySearchResults = Connection<Property> & PropertySearchResultsInput;

export type Property = {
  id: string | number;
  seoNickname: string;
  media: PropertyPrimaryImage;
  airports: PropertyAirport[];
  reviews: PropertyReviews;
  basicInformation: HotelBasicInformation;
  matchingSearchFacets: MatchingSearchFacets[];
  meetingRooms: PropertyMeetingRoomConnection;
};

export type HotelBasicInformation = {
  name: string | null;
  latitude: string | null;
  longitude: string | null;
  numberOfRooms: string | null;
  numberOfSuites: string | null;
  brand: Brand;
  openingDate: string | null;
  isFullyRenovated: boolean;
  isRecentlyRenovated: boolean;
  hasRenovatedRooms: boolean;
  newProperty: boolean;
  newLobby: boolean;
  marshaBrandCode: string;
};

export type Brand = {
  id: string;
  name: string;
  photos: {
    content: BrandContent[];
  }[];
};

export type BrandContent = {
  name: string;
  url: string;
  alternateText: string;
};

export type PropertyAirport = {
  id?: string | null;
  primaryAirport: boolean;
  distanceDetails: PropertyDistanceDetail;
};

type PropertyDistanceDetail = {
  value: number | null;
  unit: {
    code: string | null;
  };
};

export type PropertyReviews = {
  stars: {
    count: number | null;
  };
  numberOfReviews: {
    count: number | null;
  };
};

export type PropertyPrimaryImage = {
  primaryImage: PrimaryImage;
};

export type PrimaryImage = Media;

export type Media = Connection<MediaContent> | null;

export type MediaContent = {
  alternateDescription: string | null;
  title: string | null;
  imageUrls: ProductImage;
};

export type ProductImage = {
  wideHorizontal: string | null;
  square: string | null;
  classicHorizontal: string | null;
  featureHorizontal?: string | null;
};

type MatchingSearchFacets = {
  dimension: {
    code: string;
  };
};

export type PropertyMeetingRoomConnection = Connection<PropertyMeetingRoom> & {
  areaUnit: string;
  totalAreaOfMeetingSpace: number;
  totalNumberOfMeetingRooms: number;
};

type PropertyMeetingRoom = {
  area: number;
};

export type HotelMediaContent = PropertyPrimaryImage & {
  photoGallery?: GalleryContent;
};

export type PropertyMediaGallery = {
  id: number;
  media: {
    photoGallery: GalleryContent;
  };
};

export type GalleryContent = {
  activities: Media;
  dining: Media;
  eventsAndMeetings: Media;
  features: Media;
  golf: Media;
  guestRoomFloorPlan: Media;
  guestRooms: Media;
  hotelView: Media;
  nearbyAttractions: Media;
  recreationAndFitness: Media;
  services: Media;
  spa: Media;
  suites: Media;
  videoTour: Media;
  villas: Media;
  weddings: Media;
};

// -- Search Result: Query Input Types --//

export type PropertySearchQueryInput = {
  search?: PropertySearchInput;
  limit?: number;
  offset?: number;
  sort?: PropertySearchSortInput;
};

export type PropertySearchInput = {
  latitude?: number;
  longitude?: number;
  distance?: number | string;
  destination?: string;
  facets?: PropertySearchFacetInput;
  options?: {
    numberInParty?: number | string;
    largestEventSpace?: number | string;
    numberOfGuestRooms?: number | string;
  };
  groupId?: string;
  numberOfRooms?: number;
  numberOfAttendees?: number;
};

export type PropertySearchFacetInput = {
  terms?: PropertySearchFacetTypeTermInput[];
  ranges?: PropertySearchFacetTypeRangeInput[];
};

export type PropertySearchFacetTypeInput = {
  dynamicFacetType?: string;
  dimensions?: (number | string)[] | null;
};

export type PropertySearchFacetTypeTermInput = PropertySearchFacetTypeInput;
export type PropertySearchFacetTypeRangeInput = PropertySearchFacetTypeInput & {
  endpoints?: (number | string)[] | null;
};

export type PropertySearchSortInput = {
  fields?: PropertySearchSortFieldInput[];
};

export type PropertySearchSortFieldInput = {
  field?: PropertySearchSortField;
  direction?: PropertySearchSortFieldDirection;
};

export enum PropertySearchSortField {
  BRAND = 'BRAND',
  DISTANCE = 'DISTANCE',
}

export enum PropertySearchSortFieldDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}
