import { FC, useEffect, useState } from 'react';
import { LazyQueryHookOptions } from '@apollo/client';
import { PillCollection, PillProps } from '@marriott/mi-ui-library';
import { PropertySearchTermFacetBucket, PropertyFacet, PropertySearchFacet } from '@marriott/mi-groups-graphql';
import { useLocaleStore, useSearchResultsStore } from '../../../store';
import { enrichPlainFacetSelectionMap, enrichPlainFacetSelection } from '../../../utils';
import { AmenitiesFilterModal } from '../AmenitiesFilterModal';
import { ActivitiesFilterModal } from '../ActivitiesFilterModal';
import { BrandFilterModal } from '../BrandFilterModal';
import { AllFiltersModal } from '../AllFiltersModal';
import type {
  FilterBarProps,
  FacetResetConfig,
  FacetNamesConfig,
  ClearablePropertyFacet,
  FilterablePropertyFacet,
  FacetInitialConfig,
} from './FilterBar.types';
import { SearchType, type BrandItem, type SelectedFacets } from '../SearchResults.types';
import { StyledFilterBar } from './FilterBar.styles';
import {
  useSearchPropertiesByDestination,
  useSearchPropertiesByGeolocation,
  useSearchPropertiesByLocation,
} from '../SearchResults.transformer';
import { FREE_INTERNET, INTERNET } from '../../../constants';

// config for different items in the `FilterBar`
export const facetsBaseConfig: FacetInitialConfig = {
  [PropertyFacet.BRANDS]: [],
  [PropertyFacet.ACTIVITIES]: [],
  [PropertyFacet.AMENITIES]: [],
  [PropertyFacet.DISTANCE]: [],
  [PropertyFacet.TRANSPORTATION_TYPES]: [],
  [PropertyFacet.MEETINGS_EVENTS]: [],
  [PropertyFacet.STATES]: [],
  [PropertyFacet.CITIES]: [],
};

const facetsResetConfig: FacetResetConfig = {
  [PropertyFacet.ALL_FILTERS]: false,
  [PropertyFacet.ACTIVITIES]: false,
  [PropertyFacet.AMENITIES]: false,
  [PropertyFacet.BRANDS]: false,
};

export const FilterBar: FC<FilterBarProps> = ({ filters, filterModalLabels }) => {
  // props spread
  const {
    pills,
    brandFilter: { categorizedBrands },
    pillsTrackDescription,
    pillsTrackLocaltion,
    freeWifi = '',
  } = filters;

  const facetNames: FacetNamesConfig = {
    [PropertyFacet.BRANDS]: pills.brands.label,
    [PropertyFacet.ACTIVITIES]: pills.activities.label,
    [PropertyFacet.AMENITIES]: pills.amenities.label,
    [PropertyFacet.DISTANCE]: filters.distance,
    [PropertyFacet.TRANSPORTATION_TYPES]: filters.transportation,
    [PropertyFacet.MEETINGS_EVENTS]: filters.events,
    [PropertyFacet.STATES]: filters.statesProvinces,
    [PropertyFacet.CITIES]: filters.city,
  };

  // store getters and setters
  const searchType = useSearchResultsStore(state => state.searchType);
  const searchQuery = useSearchResultsStore(state => state.searchQuery);
  const availableFiltersQuery = useSearchResultsStore(state => state.availableFiltersQuery);
  const setAvailableFiltersQuery = useSearchResultsStore(state => state.setAvailableFiltersQuery);
  const facets = useSearchResultsStore(state => state.searchResults.facets);
  const facetsSelection = useSearchResultsStore(state => state.facetsSelection);
  const updateFacetByType = useSearchResultsStore(state => state.updateFacetByType);
  const refreshFacetByType = useSearchResultsStore(state => state.refreshFacetByType);
  const { locale } = useLocaleStore();

  // local state getters and setters
  const [selectedFilterType, setSelectedFilterType] = useState<PropertyFacet | ''>('');
  const [showModal, setShowModal] = useState(false);
  const [cleared, setCleared] = useState<FacetResetConfig>(facetsResetConfig);
  const [availableFilters, setAvailableFilters] = useState<PropertySearchFacet[]>(facets);
  const [isFilterClicked, setIsFilterClicked] = useState<boolean>(false);
  const [lastActivePill, setLastActivePill] = useState<HTMLElement | null>(null);

  const [getAvailableFiltersByGeolocation, { loading: availableFiltersByGeolocationLoading }] =
    useSearchPropertiesByGeolocation(true);

  const [getAvailableFiltersByLocation, { loading: availableFiltersByLocationLoading }] =
    useSearchPropertiesByLocation(true);

  const [getAvailableFiltersByDestination, { loading: availableFiltersByDestinationLoading }] =
    useSearchPropertiesByDestination(true);

  useEffect(() => {
    setAvailableFilters(facets);
    if (showModal) {
      setAvailableFiltersQuery(searchQuery);
    }
  }, [facets, searchQuery, setAvailableFiltersQuery, showModal]);

  // to get available filters once a filter is clicked
  useEffect(() => {
    if (isFilterClicked) {
      const { search, sort, offset, limit } = availableFiltersQuery;
      const { options, latitude, longitude, destination, facets } = search || {};
      const searchLocation = latitude && longitude ? { latitude, longitude } : { destination };
      const searchInput = { ...searchLocation, options, facets };
      const searchQuery = {
        search: searchInput,
        sort,
        offset,
        limit,
      };

      const uxlOptions: LazyQueryHookOptions = {
        variables: searchQuery,
        context: {
          headers: {
            'accept-language': locale,
            'uxl-mgp': 'true',
          },
        },
        fetchPolicy: 'no-cache',
        onCompleted: data => {
          const availableFilters =
            data?.search?.properties?.searchByGeolocation?.facets ||
            data?.search?.properties?.searchByLocation?.facets ||
            data?.search?.properties?.searchByDestination?.facets;

          if (availableFilters) {
            setAvailableFilters(availableFilters);
          }
        },
      };

      if (searchType === SearchType.GEOLOCATION) {
        getAvailableFiltersByGeolocation(uxlOptions);
      } else if (searchType === SearchType.LOCATION_STATE || searchType === SearchType.LOCATION_COUNTRY) {
        getAvailableFiltersByLocation(uxlOptions);
      } else {
        getAvailableFiltersByDestination(uxlOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterClicked, availableFiltersQuery, locale, searchType]);

  const loading =
    availableFiltersByGeolocationLoading || availableFiltersByLocationLoading || availableFiltersByDestinationLoading;

  // local variables
  const facetsMap: Record<FilterablePropertyFacet, PropertySearchTermFacetBucket[]> = availableFilters
    ? availableFilters.reduce((prevFacet, currentFacet) => {
        return {
          ...prevFacet,
          [currentFacet.type.code]:
            currentFacet.type.code === PropertyFacet.DISTANCE
              ? currentFacet.buckets
              : currentFacet.buckets.filter(item => item?.label),
        };
      }, facetsBaseConfig)
    : facetsBaseConfig;

  const excludeFacet = (facetKey: PropertyFacet) => {
    return (
      (searchType === SearchType.LOCATION_STATE && facetKey === PropertyFacet.STATES) ||
      (searchType === SearchType.LOCATION_COUNTRY && facetKey === PropertyFacet.COUNTRIES)
    );
  };

  // If search is by location, exclude 'STATES' filters in applied filters check. Filters should not be selected on load.
  const isFilterApplied = Object.entries(facetsSelection).some(
    ([key, facet]: [string, PropertySearchTermFacetBucket[] | (PropertySearchTermFacetBucket[] & BrandItem[])]) =>
      !excludeFacet(key as unknown as PropertyFacet) && facet?.length
  );

  // If search is by location, exclude 'STATES' filter  in total count. Count should not be shown on load.
  const selectedFiltersCount = Object.entries(facetsSelection).reduce(
    (
      total,
      [key, facet]: [string, PropertySearchTermFacetBucket[] | (PropertySearchTermFacetBucket[] & BrandItem[])]
    ) => {
      return total + (!excludeFacet(key as unknown as PropertyFacet) ? facet.length || 0 : 0);
    },
    0
  );

  const pillsData: PillProps[] = [
    {
      label: pills.allFilters.label,
      value: PropertyFacet.ALL_FILTERS,
      isActive: isFilterApplied,
      showIcon: !selectedFiltersCount,
      iconClass: 'icon-dining-filter',
      count: selectedFiltersCount,
      className: 'text-bold',
      custom_click_track_value: `${pillsTrackLocaltion} | ${pillsTrackDescription}: ${pills.allFilters.label} |internal`,
    },
    {
      label: pills.activities.label,
      value: PropertyFacet.ACTIVITIES,
      isActive:
        facetsSelection[PropertyFacet.ACTIVITIES] && facetsSelection[PropertyFacet.ACTIVITIES].length ? true : false,
      isDisabled: !facetsMap[PropertyFacet.ACTIVITIES].length,
      custom_click_track_value: `${pillsTrackLocaltion} | ${pillsTrackDescription}: ${pills.activities.label} |internal`,
    },
    {
      label: pills.amenities.label,
      value: PropertyFacet.AMENITIES,
      isActive:
        facetsSelection[PropertyFacet.AMENITIES] && facetsSelection[PropertyFacet.AMENITIES].length ? true : false,
      isDisabled: !facetsMap[PropertyFacet.AMENITIES].length,
      custom_click_track_value: `${pillsTrackLocaltion} | ${pillsTrackDescription}: ${pills.amenities.label} |internal`,
    },
    {
      label: pills.brands.label,
      value: PropertyFacet.BRANDS,
      isActive: facetsSelection[PropertyFacet.BRANDS] && facetsSelection[PropertyFacet.BRANDS].length ? true : false,
      custom_click_track_value: `${pillsTrackLocaltion} | ${pillsTrackDescription}: ${pills.brands.label} |internal`,
    },
  ];

  // handlers
  const handlePillClick = (filterType: PropertyFacet) => {
    setSelectedFilterType(filterType);
    setCleared(state => ({ ...state, [filterType]: false }));
    setLastActivePill(document?.activeElement as HTMLElement);
    setShowModal(true);
  };

  const setFocusToLastActivePill = () => {
    if (lastActivePill) {
      setTimeout(() => {
        lastActivePill?.focus();
      }, 0);
    }
  };

  const handleFilterUpdates = (
    filterType: PropertyFacet,
    selected: PropertySearchTermFacetBucket[] | BrandItem[] | SelectedFacets,
    updateAllFilters = false
  ) => {
    const isCleared = cleared[filterType as ClearablePropertyFacet];
    if (updateAllFilters) {
      for (const key in selected) {
        if (!excludeFacet(key as unknown as PropertyFacet)) {
          const filter = key as PropertyFacet;
          const selectedFilters = selected as SelectedFacets;
          const selectedFacet = selectedFilters[filter] as PropertySearchTermFacetBucket[] | BrandItem[];
          updateFacetByType(filter, isCleared && !selectedFacet.length ? [] : selectedFacet);
        }
      }
    } else {
      const selectedFilter = selected as PropertySearchTermFacetBucket[] | BrandItem[];
      updateFacetByType(filterType, isCleared && !selectedFilter.length ? [] : selectedFilter);
    }
    setShowModal(false);
    setIsFilterClicked(false);
    setFocusToLastActivePill();
  };

  const handleFliterChange = (filterType: PropertyFacet, selected: PropertySearchTermFacetBucket[] | BrandItem[]) => {
    refreshFacetByType(filterType, selected);
    setIsFilterClicked(true);
  };

  const handleClearFilters = (filterType: PropertyFacet) => {
    setCleared(state => ({ ...state, [filterType]: true }));
    setAvailableFilters(facets);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsFilterClicked(false);
    setAvailableFilters(facets);
    setFocusToLastActivePill();
  };

  const updateAmenities = () => {
    const availableAmenities = facetsMap?.[PropertyFacet.AMENITIES]
      ? [
          ...facetsMap[PropertyFacet.AMENITIES].filter(
            (searchFacet: PropertySearchTermFacetBucket) => searchFacet.code !== INTERNET
          ),
        ]
      : [];

    const freeInternetIndex = availableAmenities?.findIndex(amenity => amenity.code === FREE_INTERNET);
    if (freeInternetIndex !== -1) {
      availableAmenities[freeInternetIndex] = {
        ...availableAmenities[freeInternetIndex],
        description: freeWifi,
        label: freeWifi,
      };
    }
    return availableAmenities;
  };

  facetsMap[PropertyFacet.AMENITIES] = updateAmenities();

  const getFilterModal = () => {
    switch (selectedFilterType) {
      case PropertyFacet.ALL_FILTERS:
        return (
          <AllFiltersModal
            filterType={selectedFilterType}
            labels={{
              ...filterModalLabels,
              heading: pills.allFilters.label,
              showMoreLabel: filters.showMoreLabel,
              showLessLabel: filters.showLessLabel,
              anyDistanceLabel: filters.anyDistanceLabel,
              distanceMeasurementUnit: filters.distanceMeasurementUnit,
            }}
            facetNames={facetNames}
            facets={facetsMap}
            selectedFacets={enrichPlainFacetSelectionMap(facetsSelection, facetsMap)}
            brandCategories={categorizedBrands}
            showModal={showModal}
            isLoading={loading}
            onFilterChange={handleFliterChange}
            onApply={handleFilterUpdates}
            onClear={handleClearFilters}
            onCloseModal={handleCloseModal}
          />
        );
      case PropertyFacet.ACTIVITIES:
        return (
          <ActivitiesFilterModal
            filterType={selectedFilterType}
            labels={{ ...filterModalLabels, heading: pills.activities.label }}
            activities={facetsMap[PropertyFacet.ACTIVITIES]}
            defaultSelected={enrichPlainFacetSelection(
              facetsSelection[PropertyFacet.ACTIVITIES] as PropertySearchTermFacetBucket[],
              facetsMap[PropertyFacet.ACTIVITIES]
            )}
            showModal={showModal}
            isLoading={loading}
            onFilterChange={handleFliterChange}
            onApply={handleFilterUpdates}
            onClear={handleClearFilters}
            onCloseModal={handleCloseModal}
          />
        );
      case PropertyFacet.AMENITIES:
        return (
          <AmenitiesFilterModal
            filterType={selectedFilterType}
            labels={{ ...filterModalLabels, heading: pills.amenities.label }}
            amenities={facetsMap[PropertyFacet.AMENITIES]}
            defaultSelected={enrichPlainFacetSelection(
              facetsSelection[PropertyFacet.AMENITIES] as PropertySearchTermFacetBucket[],
              facetsMap[PropertyFacet.AMENITIES]
            )}
            showModal={showModal}
            isLoading={loading}
            onFilterChange={handleFliterChange}
            onApply={handleFilterUpdates}
            onClear={handleClearFilters}
            onCloseModal={handleCloseModal}
          />
        );
      case PropertyFacet.BRANDS:
        return (
          <BrandFilterModal
            filterType={selectedFilterType}
            labels={{ ...filterModalLabels, heading: pills.brands.label }}
            brandCategories={categorizedBrands}
            availableBrands={facetsMap[PropertyFacet.BRANDS]}
            selectedBrands={facetsSelection[PropertyFacet.BRANDS]}
            showModal={showModal}
            isLoading={loading}
            onFilterChange={handleFliterChange}
            onApply={handleFilterUpdates}
            onClear={handleClearFilters}
            onCloseModal={handleCloseModal}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledFilterBar className="pb-4">
      <PillCollection pills={pillsData} onChange={value => handlePillClick(value as PropertyFacet)} />
      {showModal ? getFilterModal() : null}
    </StyledFilterBar>
  );
};
