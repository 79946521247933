import {
  DocumentNode,
  LazyQueryHookExecOptions,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useDttStore } from '../store';

type TransformerOptions<TCurrentContract, TFutureContract, TOptions> = {
  options?: TOptions;
  onTransform: (data: TCurrentContract) => TFutureContract;
};

type QueryTransformerOptions<TCurrentContract, TFutureContract, TOptions> = TransformerOptions<
  TCurrentContract,
  TFutureContract,
  TOptions
> & {
  currentQuery: DocumentNode;
  dttQuery: DocumentNode;
};

type MutationTransformerOptions<TCurrentContract, TFutureContract, TOptions> = TransformerOptions<
  TCurrentContract,
  TFutureContract,
  TOptions
> & {
  currentMutation: DocumentNode;
  dttMutation: DocumentNode;
};

function useTransformer<TCurrentContract, TFutureContract>(): [
  boolean,
  TFutureContract | undefined,
  (response: TFutureContract, onTransform: (response: TCurrentContract) => TFutureContract) => TFutureContract,
  () => void
] {
  const { isDtt } = useDttStore();
  const [transformedData, setTransformedData] = useState<TFutureContract>();

  const transformer = (
    response: TCurrentContract | TFutureContract,
    onTransform: (response: TCurrentContract) => TFutureContract
  ): TFutureContract => {
    const isDtt = useDttStore.getState().isDtt;
    const clonedResponse = JSON.parse(JSON.stringify(response));
    const transformedResponse = isDtt ? (response as TFutureContract) : onTransform(clonedResponse as TCurrentContract);
    setTransformedData(transformedResponse);
    return transformedResponse;
  };

  const reset = () => {
    setTransformedData(undefined);
  };

  return [isDtt, transformedData, transformer, reset];
}

function useQueryTransformer<TCurrentContract, TFutureContract = TCurrentContract>({
  currentQuery,
  dttQuery,
  options = {},
  onTransform,
}: QueryTransformerOptions<TCurrentContract, TFutureContract, QueryHookOptions>): QueryResult<
  TFutureContract,
  OperationVariables
> {
  const [isDtt, transformedData, transformer, reset] = useTransformer<TCurrentContract, TFutureContract>();
  const { onCompleted: onQueryCompleted, ...queryOptions } = options;

  const { data: _data, ...rest } = useQuery<TFutureContract>(isDtt ? dttQuery : currentQuery, {
    ...queryOptions,
    onCompleted: response => {
      const data = transformer(response, onTransform);
      onQueryCompleted?.(data);
    },
  });

  useEffect(() => {
    if (rest.loading) {
      reset();
    }
  }, [rest.loading, reset]);

  return { data: transformedData, ...rest };
}

function useLazyQueryTransformer<TCurrentContract, TFutureContract = TCurrentContract>({
  currentQuery,
  dttQuery,
  options = {},
  onTransform,
}: QueryTransformerOptions<TCurrentContract, TFutureContract, LazyQueryHookOptions>): LazyQueryResultTuple<
  TFutureContract,
  OperationVariables
> {
  const [isDtt, transformedData, transformer, reset] = useTransformer<TCurrentContract, TFutureContract>();
  const { onCompleted: onQueryCompleted, ...queryOptions } = options;

  const [fetchDataFromQuery, { data: _data, ...rest }] = useLazyQuery<TFutureContract>(
    isDtt ? dttQuery : currentQuery,
    {
      ...queryOptions,
      onCompleted(response) {
        const data = transformer(response, onTransform);
        onQueryCompleted?.(data);
      },
    }
  );

  const fetchData = useCallback(
    (lazyQueryOptions: Partial<LazyQueryHookExecOptions<TFutureContract, OperationVariables>> = {}) => {
      const { onCompleted: onQueryCompleted, ...restLazyQueryOptions } = lazyQueryOptions;
      const formattedOptions: LazyQueryHookOptions = { ...restLazyQueryOptions };
      if (onQueryCompleted) {
        formattedOptions.onCompleted = response => {
          const data = transformer(response, onTransform);
          onQueryCompleted(data);
        };
      }
      return fetchDataFromQuery(formattedOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (rest.loading) {
      reset();
    }
  }, [rest.loading, reset]);

  return [fetchData, { data: transformedData, ...rest }];
}

function useMutationTransformer<TCurrentContract, TFutureContract = TCurrentContract>({
  currentMutation,
  dttMutation,
  options = {},
  onTransform,
}: MutationTransformerOptions<TCurrentContract, TFutureContract, MutationHookOptions>): MutationTuple<
  TFutureContract,
  OperationVariables
> {
  const [isDtt, transformedData, transformer] = useTransformer<TCurrentContract, TFutureContract>();
  const { onCompleted: onMutationCompleted, ...initialMutationOptions } = options;

  const [sendDataToMutation, { data: _data, ...rest }] = useMutation<TFutureContract>(
    isDtt ? dttMutation : currentMutation,
    {
      ...initialMutationOptions,
      onCompleted(response) {
        const data = transformer(response, onTransform);
        onMutationCompleted?.(data);
      },
    }
  );

  const sendData = useCallback(
    (mutationOptions: Partial<MutationFunctionOptions<TFutureContract, OperationVariables>> = {}) => {
      const { onCompleted: onMutationCompleted, ...restMutationOptions } = mutationOptions;
      const formattedOptions: MutationHookOptions = { ...restMutationOptions };
      if (onMutationCompleted) {
        formattedOptions.onCompleted = response => {
          const data = transformer(response, onTransform);
          onMutationCompleted(data);
        };
      }
      return sendDataToMutation(formattedOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [sendData, { data: transformedData, ...rest }];
}

export { useQueryTransformer, useLazyQueryTransformer, useMutationTransformer };
