import {
  getCurrentDateObject,
  getLocalizedDate,
  getNextDateObject,
  getTotalNumberOfDays,
} from '@marriott/mi-ui-library';
import moment from 'moment';
import { DateObject } from '../molecules/DatePicker/DatePicker.types';
import {
  CALENDAR_LONG_WEEKDAY_NAMES,
  CALENDAR_SHORT_MONTH_NAMES,
  CALENDAR_SHORT_WEEKDAY_NAMES,
  DEFAULT_LANG,
  LOCALE_MAP,
  VIETNAMESE_LANG,
  THAI_LANG,
  THAI_BUDDHIST_ERA_OFFSET,
  NON_DISPLAY_FORMATS,
} from '../constants';
import { useLocaleStore } from '../store';
import { LocaleUtils } from 'react-day-picker';

export const getDefaultDates = (selectedStartDate?: DateObject | null, selectedEndDate?: DateObject | null) => {
  const defaultStartDate = selectedStartDate || getCurrentDateObject();
  const defaultEndDate = selectedEndDate || getNextDateObject(defaultStartDate);
  return { defaultStartDate, defaultEndDate };
};

export const getDatesLabel = (
  startDate: DateObject | null,
  endDate: DateObject | null,
  datesLabel: string,
  nightLabel: string,
  nightsLabel: string
) => {
  const nightCountValue = startDate && endDate ? Number(getTotalNumberOfDays(startDate, endDate).toFixed(0)) : 1;
  return `${datesLabel} (${nightCountValue} ${nightCountValue === 1 ? nightLabel : nightsLabel})`;
};

export const getFormattedDate = (date: DateObject | null, dateFormat = 'dateWithDayMonthAndYear', locale?: string) =>
  date?.isValid() ? getLocalizedDate(date, getLocaleValue(dateFormat, locale), locale) : '';

export const getFormattedDateObject = (date: string, dateFormat = 'hyphenatedDateWithMonthNoAndYear') =>
  moment(date, getLocaleValue(dateFormat));

export const getFormattedDateString = (date: string, dateFormat = 'hyphenatedDateWithMonthNoAndYear') => {
  const locale = getCurrentLocale();
  const formattedDate = moment(date).format(getLocaleValue(dateFormat));
  return NON_DISPLAY_FORMATS.includes(dateFormat) || locale !== THAI_LANG
    ? formattedDate
    : applyThaiYear(formattedDate, moment(date).year());
};

export const getFormattedCalendarDate = (date: string, dateFormat: string) =>
  getFormattedDate(getFormattedDateObject(date, 'dateWithDayMonthAndYear'), dateFormat);

export const getFormattedDisplayDate = (
  date: DateObject | null,
  dateFormat = 'dateWithDayMonthAndYear',
  locale?: keyof typeof LOCALE_MAP
) => {
  const formatedDate = getFormattedDate(date, dateFormat, locale as string);
  return locale === THAI_LANG ? applyThaiYear(formatedDate, date?.year() as number) : formatedDate;
};

export const applyThaiYear = (date: string, gregorianYear: number) =>
  date.replace(gregorianYear?.toString(), (gregorianYear + THAI_BUDDHIST_ERA_OFFSET).toString());

export const getFormattedDates = (startDate: DateObject | null, endDate?: DateObject | null) => {
  const DATE_FORMAT = getLocaleValue('dateWithDayAndMonth');
  const localizedStartDate = startDate?.isValid() ? getLocalizedDate(startDate, DATE_FORMAT) : '';
  const localizedEndDate = endDate?.isValid() ? ` - ${getLocalizedDate(endDate, DATE_FORMAT)}` : '';
  return `${localizedStartDate}${localizedEndDate}`;
};

export const getFormattedDatesObject = (startDate: string, endDate: string) => {
  const formattedStartDate = getFormattedDateObject(startDate);
  const formattedEndDate = getFormattedDateObject(endDate);

  return {
    startDate: getFormattedDate(formattedStartDate),
    endDate: getFormattedDate(formattedEndDate),
    dates: getFormattedDates(formattedStartDate, formattedEndDate),
  };
};

export const getDatesInRange = (startDate: string, endDate: string) => {
  const formattedStartDate = getFormattedDateObject(startDate);
  const formattedEndDate = getFormattedDateObject(endDate);
  const dates = [];
  while (formattedStartDate.isSameOrBefore(formattedEndDate)) {
    dates.push({ date: getFormattedDate(formattedStartDate, 'slashedDateWithMonthNoAndYear') });
    formattedStartDate.add(1, 'day');
  }
  return dates;
};

export const getDayOfTheWeek = (date?: string, format = 'dddd') =>
  moment(date || Date.now())
    .clone()
    .locale('en')
    .format(format);

export const getDurationInDays = (startDate?: string, endDate?: string) => {
  const startDateObject = new Date(startDate || Date.now()) as unknown as number;
  const endDateObject = new Date(endDate || Date.now()) as unknown as number;
  const durationInMilliseconds = Math.abs(endDateObject - startDateObject);
  return Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));
};

export const checkWeekdayWeekendStay = (startDate?: string, endDate?: string) => {
  const startDateObject = new Date(startDate || Date.now());
  const endDateObject = new Date(endDate || Date.now());

  let hasWeekday = false;
  let hasWeekend = false;

  while (startDateObject <= endDateObject) {
    const dayIndex = startDateObject.getDay();
    if (dayIndex === 0 || dayIndex === 6) {
      hasWeekend = true;
    } else {
      hasWeekday = true;
    }
    startDateObject.setDate(startDateObject.getDate() + 1);
  }

  if (hasWeekend && hasWeekday) {
    return 'Weekday and Weekend Stay';
  } else if (hasWeekday) {
    return 'Weekdays Only';
  } else if (hasWeekend) {
    return 'Weekend Only';
  } else {
    return 'No weekdays or weekends exist between the given dates';
  }
};

export const isToday = (date: string, dateFormat = 'dateWithDayMonthAndYear') => {
  const formattedDate = getFormattedDateObject(date, dateFormat);
  return formattedDate.isSame(getCurrentDateObject(), 'date');
};

export const isFutureDate = (date: string, dateFormat = 'hyphenatedDateWithMonthNoAndYear') => {
  const formattedDate = getFormattedDateObject(date, dateFormat);
  return formattedDate.isAfter(getCurrentDateObject(), 'date');
};

export const convertTo24HourTime = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');
  const [hours, minutes] = time.split(':');
  let updatedHours = +hours;

  if (updatedHours === 12 && modifier === 'AM') {
    updatedHours = 24;
  } else if (updatedHours !== 12 && modifier === 'PM') {
    updatedHours += 12;
  }

  return `${updatedHours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

export const getLocaleValue = (key: string, selectedLocale?: string) => {
  const locale = selectedLocale || getCurrentLocale() || DEFAULT_LANG;
  const localeData = LOCALE_MAP[locale] || LOCALE_MAP[DEFAULT_LANG];
  return localeData[key];
};

export const getCurrentLocale = () => {
  return useLocaleStore.getState().locale;
};

export const setCalendarOptions = ({
  shortWeekdayNames = CALENDAR_SHORT_WEEKDAY_NAMES,
  longWeekdayNames = CALENDAR_LONG_WEEKDAY_NAMES,
  shortMonthNames = CALENDAR_SHORT_MONTH_NAMES,
  longMonthNames = LocaleUtils.getMonths() as string[],
}) => {
  const locale = getCurrentLocale();
  const momentConfig =
    locale === VIETNAMESE_LANG || locale === THAI_LANG ? { monthsParseExact: true, weekdaysParseExact: true } : {};
  moment.locale('locale', {
    weekdaysShort: shortWeekdayNames,
    weekdays: longWeekdayNames,
    monthsShort: shortMonthNames,
    months: longMonthNames,
    ...momentConfig,
  });
};

export const isSameMonthAndYear = (d1: DateObject | null, d2: DateObject | null) => {
  return d1?.isValid() ? d1.isSame(d2, 'year') && d1.isSame(d2, 'month') : false;
};
