import {
  PropertySearchQueryInput,
  PropertySearchFacetInput,
  PropertyFacet,
  PropertySearchSortField,
  PropertySearchSortFieldDirection,
  PropertySearchSortInput,
  PropertySearchInput,
  PropertyMeetingRoomConnection,
  PropertyAirport,
  Node,
  PropertyContent,
  PropertySearchFacetTypeTermInput,
  PropertySearchFacetTypeRangeInput,
  PropertySearchFacetTypeInput,
  PropertySearchTermFacetBucket,
  GroupRatesPropertySearchQueryInput,
  GroupRate,
  HotelBasicInformation,
  GuestRoomRate,
  EventSpaceRate,
  Amount,
} from '@marriott/mi-groups-graphql';
import {
  SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS,
  SEARCH_RESULTS_PAGE_SIZE,
  CATERING_CODE,
  HIGH_SPEED_INTERNET_ACCESS_CODE,
  FEET_TO_METERS,
  SQFEET_TO_SQMETERS,
  FACET_KEYS_TO_QUERY_PARAMS,
  QG_MIN_GUEST_ROOMS,
  QG_MAX_ATTENDEES,
  QG_MAX_BOOKING_DAY_COUNT,
  QG_MAX_GUEST_ROOMS,
  QG_MIN_BOOKING_DAY_COUNT,
  QG_MAX_LENGTH_OF_STAY,
  QG_SEARCH_REQUEST_CODE,
  QG_ELIGIBLE_LOCALES,
  QG_ENABLED,
  QG_MIN_LENGTH_OF_STAY,
  RFP_EXCLUDED_BRANDS,
  DEFAULT_LANG,
  LOCALE_MAP,
  RFP_OPENING_SOON_DURATION,
  HWS_BRANDS,
  RITZ_CARLTON,
  MILES_TO_KILOMETERS,
  MILES,
  KILOMETERS,
  KILOMETERS_CN,
  SQFEET,
  SQMETERS,
  SQMETERS_CN,
  KILOMETERS_TO_MILES,
  SQMETERS_TO_SQFEET,
  RITZ_CARLTON_DOMAIN,
  MARRIOTT_DOMAIN,
  CHINESE_LANG,
  CHINESE_DOMAIN_SUFFIX,
  SPANISH_LANG,
  CITY_EXPRESS,
} from '../constants';
import { EquipmentAndServices } from '../molecules/PropertyQuickViewModal/PropertyQuickViewModal.types';
import { FacetSelectionMap, FilterablePropertyFacet } from '../organisms/SearchResults/FilterBar/FilterBar.types';
import { getCurrentDateObject, getTotalNumberOfDays } from '@marriott/mi-ui-library';
import {
  getDurationInDays,
  getFormattedDateObject,
  getFormattedDateString,
  getLocaleValue,
  getCurrentLocale,
} from './date';
import { getBasicSearchCriteria, getSearchQueryOptions } from './search';
import { Property } from '../organisms/SearchResults/SearchResults.types';
import { formatAmount } from './currency';
import { useDttStore } from '../store';

export const getInitialSearchSortInput = (): PropertySearchSortInput => {
  return {
    fields: [
      {
        field: PropertySearchSortField.DISTANCE,
        direction: PropertySearchSortFieldDirection.ASC,
      },
    ],
  };
};

export const getUpdatedSearchSortInput = (
  field: PropertySearchSortField,
  direction: PropertySearchSortFieldDirection
): PropertySearchSortInput => {
  return {
    fields: [
      {
        field,
        direction,
      },
    ],
  };
};

export const getInitialSearchFacetInput = (): PropertySearchFacetInput => {
  return {
    terms: [
      {
        dynamicFacetType: PropertyFacet.BRANDS,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.AMENITIES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.ACTIVITIES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.PROPERTY_TYPES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.TRANSPORTATION_TYPES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.HOTEL_SERVICE_TYPES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.CITIES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.COUNTRIES,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.MEETINGS_EVENTS,
        dimensions: [],
      },
      {
        dynamicFacetType: PropertyFacet.STATES,
        dimensions: [],
      },
    ],
    ranges: [
      {
        dynamicFacetType: PropertyFacet.DISTANCE,
        dimensions: [],
        endpoints: SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS,
      },
    ],
  };
};

export const getUpdatedSearchFacetInput = ({
  terms = [],
  ranges = [],
}: {
  terms?: PropertySearchFacetTypeTermInput[];
  ranges?: PropertySearchFacetTypeRangeInput[];
}) => {
  const initialSearchFacetInput = getInitialSearchFacetInput();
  const selectedTermFacetTypeNames = terms?.map(termFacetType => termFacetType.dynamicFacetType);
  const remainingPropertySearchFacetTypes =
    initialSearchFacetInput.terms?.filter(term => !selectedTermFacetTypeNames.includes(term.dynamicFacetType)) || [];
  return {
    terms: [...remainingPropertySearchFacetTypes, ...terms],
    ranges:
      ranges.length && ranges[0].dimensions?.length
        ? [ranges[0]]
        : [
            {
              dynamicFacetType: PropertyFacet.DISTANCE,
              dimensions: [],
              endpoints: SEARCH_RESULTS_QUERY_DISTANCE_ENDPOINTS,
            },
          ],
  };
};

export const getInitialSearchInput = (): PropertySearchInput => {
  return {
    latitude: 0,
    longitude: 0,
    distance: 0,
    options: {},
    facets: getInitialSearchFacetInput(),
  };
};

export const getInitialSearchQueryInput = (): PropertySearchQueryInput => {
  return {
    search: getInitialSearchInput(),
    sort: getInitialSearchSortInput(),
    offset: 0,
    limit: SEARCH_RESULTS_PAGE_SIZE,
  };
};

export function generatePaginationLabel(
  paginationLabel: string,
  currentPage: number,
  pageSize: number,
  totalSearchResults: number
) {
  const start = (currentPage - 1) * pageSize + 1;
  const end = Math.min(start + pageSize - 1, totalSearchResults);
  const range = `${start}-${end}`;
  return paginationLabel.replace('{range}', range).replace('{totalCount}', totalSearchResults.toString());
}

export const getPropertyItemValue = (value: string | number | null, unit = '') => {
  return value ? `${value} ${unit}` : '';
};

export const getPropertyAirportDistanceLabel = (
  airports: PropertyAirport[],
  label: string | null,
  showAirportId?: boolean
): string => {
  if (!airports || airports.length === 0) {
    return '';
  }
  const airport = airports?.find((airport: PropertyAirport) => {
    return airport.primaryAirport;
  });

  const { distance, unit } = getDistanceValueAndUnitForLocale(
    airport?.distanceDetails?.value as number,
    airport?.distanceDetails?.unit?.code as string
  );

  if (!distance || !unit) return '';

  return getPropertyItemValue(
    `${distance} ${unit} ${showAirportId ? label?.replace('{airportId}', airport?.id as string) : label}`
  ).trimEnd();
};

export const getPropertyLargestRoomArea = (edges: PropertyMeetingRoomConnection['edges']) => {
  if (!edges || edges.length === 0) {
    return '';
  }
  const largestMeetingRoom = edges.reduce((previous, current) => {
    return previous['node']['area'] > current['node']['area'] ? previous : current;
  });
  return getPropertyItemValue(largestMeetingRoom.node?.area);
};

export const getAddress = (addressLine: string, city: string, state: string, country: string, postalCode: string) => {
  let address = addressLine ? `${addressLine}` : '';
  address = city ? `${address} ${city}` : address;
  const addressElements = [address, state, country, postalCode];
  return addressElements.filter(element => !!element).join(', ');
};

export const getReviewsUrl = (hotelName: string, brandId?: string) => {
  const rlmLocale = getLocaleValue('rlmLocale');
  const partialUrl =
    brandId === RITZ_CARLTON
      ? `${RITZ_CARLTON_DOMAIN}/${rlmLocale.slice(0, 2)}`
      : `${MARRIOTT_DOMAIN}${rlmLocale === CHINESE_LANG.toLowerCase() ? CHINESE_DOMAIN_SUFFIX : `/${rlmLocale}`}`;
  return `${partialUrl}/hotels/${hotelName}/${HWS_BRANDS.includes(brandId as string) ? 'overview' : 'reviews'}`;
};

export const getHotelOverviewUrl = (hotelName: string, showMap = false) => {
  return `${MARRIOTT_DOMAIN}/${getLocaleValue('rlmLocale')}/hotels/${hotelName}/overview${showMap ? '/?map' : ''}`;
};

export const convertFeetToMeters = (feet: number) => Math.round(feet * FEET_TO_METERS * 10) / 10;

export const convertSqFeetToSqMeters = (sqFeet: number) => Math.round(sqFeet * SQFEET_TO_SQMETERS);

export const convertSqMetersToSqFeet = (sqMeters: number) => Math.round(sqMeters * SQMETERS_TO_SQFEET);

const convertMilesToKilometers = (miles: number) => Math.ceil((miles as number) * MILES_TO_KILOMETERS);

const convertKilometersToMiles = (kilometers: number) => Math.ceil((kilometers as number) * KILOMETERS_TO_MILES);

export const getAreaValueAndUnitForLocale = (area: number, unit: string, unitLabel?: string) => {
  const locale = getCurrentLocale() || DEFAULT_LANG;
  let updatedArea = area;
  let updatedUnit = unit;

  if (locale === DEFAULT_LANG) {
    if (unit === SQMETERS) {
      updatedArea = convertSqMetersToSqFeet(area);
      updatedUnit = SQFEET;
    }
  } else {
    if (unit === SQFEET) {
      updatedArea = convertSqFeetToSqMeters(area);
    }
    updatedUnit = unitLabel ? unitLabel : locale === CHINESE_LANG ? SQMETERS_CN : SQMETERS;
  }

  return { area: updatedArea, unit: updatedUnit };
};

const getDistanceValueAndUnitForLocale = (distance: number, unit: string) => {
  if (!distance && !unit) return { distance: '', unit: '' };

  const locale = getCurrentLocale() || DEFAULT_LANG;
  let updatedDistance = distance;
  let updatedUnit = unit;

  if (locale === DEFAULT_LANG) {
    if (unit === KILOMETERS) {
      updatedDistance = convertKilometersToMiles(distance);
      updatedUnit = MILES;
    }
  } else {
    if (unit === MILES) {
      updatedDistance = convertMilesToKilometers(distance);
    }
    updatedUnit = locale === CHINESE_LANG ? KILOMETERS_CN : KILOMETERS;
  }

  return { distance: formatAmount(updatedDistance, 1), unit: updatedUnit };
};

export const getEquipmentAndServices = (
  meetingEquipmentAndServices: Node<PropertyContent>[],
  facilitiesAndServices: PropertyContent[],
  labels: EquipmentAndServices
): Record<string, string[]> => {
  const { highSpeedInternetAccess: highSpeedInternetAccessLabel, catering: cateringLabel } = labels;

  if (!Array.isArray(meetingEquipmentAndServices) && !Array.isArray(facilitiesAndServices)) {
    return {};
  }

  const equipmentAndServices = meetingEquipmentAndServices
    ?.filter(item => item.node?.categoryType.code !== HIGH_SPEED_INTERNET_ACCESS_CODE)
    .reduce((equipmentAndService, item) => {
      const listDescription = item.node.type?.description;
      const itemDescription = item.node.categoryType.description;
      if (!equipmentAndService[listDescription]) {
        equipmentAndService[listDescription] = [];
      }
      equipmentAndService[listDescription].push(itemDescription);
      return equipmentAndService;
    }, {} as Record<string, string[]>);

  const highSpeedInternetAccess = meetingEquipmentAndServices?.filter(
    item => item.node.categoryType.code === HIGH_SPEED_INTERNET_ACCESS_CODE
  );

  if (highSpeedInternetAccess?.length) {
    const itemKeyDescription = highSpeedInternetAccess[0].node.categoryType.description;
    const itemValueDescriptions = highSpeedInternetAccess.map(item => item.node.description);
    const highSpeedInternetService = `${itemKeyDescription}: ${itemValueDescriptions.join(', ')}`;
    equipmentAndServices[highSpeedInternetAccessLabel] = [highSpeedInternetService];
  }

  const cateringServices = facilitiesAndServices
    ?.filter(item => item?.categoryType?.code.includes(CATERING_CODE))
    .map(item => item.categoryType?.description);

  if (cateringServices?.length) {
    equipmentAndServices[cateringLabel] = cateringServices;
  }

  return equipmentAndServices;
};

export const getQueryParams = <T extends Record<string, string>>(queryString: string) => {
  const searchParams = new URLSearchParams(queryString);
  const params: Record<string, string> = {};
  searchParams.forEach((paramValue, paramKey) => {
    params[paramKey] = paramValue;
  });
  return params as T;
};

export const updateRouteWithSearchQuery = (
  searchQuery: PropertySearchQueryInput | GroupRatesPropertySearchQueryInput,
  additionalParams: Record<string, string>
) => {
  const { search, sort, offset, limit } = searchQuery;

  // Form Data
  const searchUrlParams = new URLSearchParams(
    getBasicSearchCriteria(getSearchQueryOptions(getQueryParams(window.location.search)))
  );

  // Facets
  if (search?.facets?.terms && search.facets.terms.length) {
    search.facets.terms
      .filter(
        term =>
          term.dynamicFacetType &&
          FACET_KEYS_TO_QUERY_PARAMS[term.dynamicFacetType as PropertyFacet] &&
          term.dimensions?.length
      )
      .forEach(term => {
        const queryParamName = FACET_KEYS_TO_QUERY_PARAMS[term?.dynamicFacetType as PropertyFacet];
        if (queryParamName && term.dimensions?.length) searchUrlParams.set(queryParamName, term.dimensions.join(','));
      });

    search.facets.ranges
      ?.filter(term => FACET_KEYS_TO_QUERY_PARAMS[term.dynamicFacetType as PropertyFacet])
      .forEach(term => {
        const queryParamName = FACET_KEYS_TO_QUERY_PARAMS[term?.dynamicFacetType as PropertyFacet];
        if (queryParamName && term.dimensions?.length) searchUrlParams.set(queryParamName, term.dimensions.join(','));
      });

    search.facets.terms.forEach(term => {
      if (term.dynamicFacetType === PropertyFacet.STATES && !term.dimensions?.length) {
        const queryParamName = FACET_KEYS_TO_QUERY_PARAMS[term.dynamicFacetType];
        searchUrlParams.delete(queryParamName as string);
      }
    });
  }

  // Sort
  if (sort) {
    sort.fields?.forEach(sortField => {
      searchUrlParams.set('sortBy', sortField.field?.toLowerCase() as string);
      searchUrlParams.set('sortOrder', sortField.direction?.toLowerCase() as string);
    });
  }

  // Pagination
  if (limit && offset) {
    searchUrlParams.set('page', `${offset / limit + 1}`);
  }

  // Additional Parameters
  for (const [key, value] of Object.entries(additionalParams)) {
    searchUrlParams.set(key, value);
  }

  window.history.replaceState({}, '', decodeURIComponent(`?${searchUrlParams.toString()}`));
};

export const isMapViewVisible = () => {
  const view = getQueryParams(window.location.search)['view'];
  return view === 'map';
};

export const updateRouteWithView = (isMapView: boolean) => {
  const searchUrlParams = new URLSearchParams(window.location.search);
  searchUrlParams.set('view', isMapView ? 'map' : 'list');
  window.history.replaceState({}, '', decodeURIComponent(`?${searchUrlParams.toString()}`));
};

export const updateRouteWithDestination = (destination: string) => {
  const searchUrlParams = new URLSearchParams(window.location.search);
  searchUrlParams.set('destination', destination);
  window.history.replaceState({}, '', decodeURIComponent(`?${searchUrlParams.toString()}`));
};

/* Store utitlies */

// Plain selection map is the bare-minimum selection map, that expects to be enriched later
// with more/useful attributes. It's useful to initiate the store key with enough information -
// - to make the initial call with state in the URL
// - to inform the UI elements of the cuurently selected state
export const createPlainFacetSelectionMap = (terms: PropertySearchFacetTypeInput[] = []) => {
  return (
    terms.length
      ? terms.reduce((prevFacet, currentFacet) => {
          return {
            ...prevFacet,
            [currentFacet.dynamicFacetType as unknown as PropertyFacet]:
              currentFacet.dynamicFacetType === PropertyFacet.BRANDS
                ? currentFacet.dimensions?.map(dimension => ({
                    brandTagId: dimension,
                    brandTagTitle: '',
                  }))
                : currentFacet.dimensions?.map(dimension => ({
                    code: dimension,
                    label: '',
                    description: '',
                    count: 0,
                  })),
          };
        }, {})
      : {}
  ) as Partial<FacetSelectionMap>;
};

// Enriches the plain facets map prepared from all the applicable facet
// URL parameters into a shape that UI components expects to show the
// selection/checked state.
//
// Levarages and run `enrichPlainFacetSelection`against all the facets/filterable parameters

export const enrichPlainFacetSelectionMap = (
  termFacetsByProperty: Partial<FacetSelectionMap>,
  enrichedTermFacetsByProperty: Record<FilterablePropertyFacet, PropertySearchTermFacetBucket[]>
): FacetSelectionMap => {
  const enrichedSelection: Partial<Record<FilterablePropertyFacet, PropertySearchTermFacetBucket[]>> = {};
  Object.entries(termFacetsByProperty).forEach(([propertyFacet, searchTermFacetBuckets]) => {
    enrichedSelection[propertyFacet as FilterablePropertyFacet] = enrichPlainFacetSelection(
      searchTermFacetBuckets,
      enrichedTermFacetsByProperty[propertyFacet as FilterablePropertyFacet]
    );
  });
  return enrichedSelection as FacetSelectionMap;
};

// Enriches a single plain facet prepared from a URL param
//
// Components like `checkboxes` that search result uses expects both `code`
// and `label` in the selection state, whereas URL can only provide the `code`.
// Enriching adds the missing piece i.e. adds `label` by reading UXL response's
// facets state.
//
export const enrichPlainFacetSelection = (
  termFacets: PropertySearchTermFacetBucket[] = [],
  enrichedTermFacets: PropertySearchTermFacetBucket[]
): PropertySearchTermFacetBucket[] => {
  if (!termFacets.length) return termFacets;
  const hasNonEnrichedTermFacets = termFacets.some(
    termFacet => (termFacet.label || termFacet.label === '') && termFacet.code
  );
  if (hasNonEnrichedTermFacets) {
    const selectionCodes = termFacets.map(facetBucket => facetBucket.code);
    return enrichedTermFacets?.filter(enrichedTermFacet => {
      // distance facet will have index instead of code so we need to consider this condition
      const enrichedTermFacetCode = enrichedTermFacet.code ?? enrichedTermFacet.index?.toString();
      return selectionCodes.includes(enrichedTermFacetCode);
    });
  }
  return termFacets;
};

export const isRfpEligible = (brand: { id: string }, isOpeningSoon: boolean, openingDate: string) => {
  const today = new Date().toLocaleDateString();
  return (
    !RFP_EXCLUDED_BRANDS?.includes(brand?.id) &&
    (!isOpeningSoon || getDurationInDays(today, openingDate) <= RFP_OPENING_SOON_DURATION)
  );
};

export const isQuickGroupEligible = (
  locale: string,
  guestRooms: number,
  attendees: number,
  startDate: string | undefined,
  endDate: string | undefined
) => {
  const isDtt = useDttStore.getState().isDtt;

  if (QG_ENABLED !== 'true' || isDtt) return false; // TODO:DTT-Migration: Remove isDtt check once Search with pricing and QG Migration starts

  if (!QG_ELIGIBLE_LOCALES?.split(',').includes(locale)) return false;

  const isGuestRoomsInRange = guestRooms >= QG_MIN_GUEST_ROOMS && guestRooms <= QG_MAX_GUEST_ROOMS;
  const isAttendeesInRange = attendees > 0 && attendees <= QG_MAX_ATTENDEES;
  const isGuestRoomsAttendeesInRange =
    guestRooms && attendees ? isGuestRoomsInRange && isAttendeesInRange : isGuestRoomsInRange || isAttendeesInRange;

  let isStayLengthValid = false;
  if (startDate && endDate) {
    const start = getFormattedDateObject(startDate);
    const end = getFormattedDateObject(endDate);
    const lengthOfStay = Number(getTotalNumberOfDays(start, end).toFixed(0));
    isStayLengthValid = lengthOfStay >= QG_MIN_LENGTH_OF_STAY && lengthOfStay <= QG_MAX_LENGTH_OF_STAY;
  }

  let isBookingInRange = false;
  if (startDate) {
    const today = getCurrentDateObject();
    const bookingDayCount = Number(getTotalNumberOfDays(getFormattedDateObject(startDate), today).toFixed(0));
    isBookingInRange = bookingDayCount >= QG_MIN_BOOKING_DAY_COUNT && bookingDayCount <= QG_MAX_BOOKING_DAY_COUNT;
  }

  return isGuestRoomsAttendeesInRange && isStayLengthValid && isBookingInRange;
};

export const getGroupRatesSearchRequestCode = (numberOfRooms: number, numberOfAttendees: number) => {
  if (numberOfRooms && numberOfAttendees) {
    return QG_SEARCH_REQUEST_CODE.NUM_ROOMS_AND_ATTENDEES;
  } else if (numberOfRooms) {
    return QG_SEARCH_REQUEST_CODE.NUM_ROOMS_ONLY;
  } else {
    return QG_SEARCH_REQUEST_CODE.NUM_ATTENDEES_ONLY;
  }
};

export const showBookNow = (groupRate: GroupRate, guestRooms = 0, attendees = 0, locale?: string, brand?: string) => {
  if (locale === SPANISH_LANG && brand !== CITY_EXPRESS) {
    return false;
  }

  const isRoomRatesAvailable = !!groupRate.roomRates?.length;
  const isEventSpaceRatesAvailable = !!groupRate.eventSpaceRates?.length;
  const isAvailabilityCodeOne = groupRate.availability?.code === '1';

  if (+guestRooms && +attendees) {
    return isRoomRatesAvailable && isEventSpaceRatesAvailable && isAvailabilityCodeOne;
  } else if (+attendees) {
    return isEventSpaceRatesAvailable;
  } else if (+guestRooms) {
    return isRoomRatesAvailable && isAvailabilityCodeOne;
  } else {
    return false;
  }
};

export const moveDecimalPoint = (number: number, positions: number) => number / Math.pow(10, positions) || '';

export const getPropertyBadge = (
  basicInformation: HotelBasicInformation,
  propertyLabels: Property,
  isOpeningSoon: boolean
) => {
  const { openingDate, isFullyRenovated, isRecentlyRenovated, hasRenovatedRooms, newProperty, newLobby } =
    basicInformation || {};
  const { openingSoon, fullyRenovated, recentlyRenovated, renovatedRooms, newHotel, renovatedLobby } = propertyLabels;

  switch (true) {
    case isOpeningSoon:
      return `${openingSoon} ${getFormattedDateString(openingDate as string, 'monthAndYear')}`;
    case isFullyRenovated:
      return fullyRenovated;
    case isRecentlyRenovated:
      return recentlyRenovated;
    case hasRenovatedRooms:
      return renovatedRooms;
    case newProperty:
      return newHotel;
    case newLobby:
      return renovatedLobby;
    default:
      return '';
  }
};

const getMinRate = (rates: GuestRoomRate[] | EventSpaceRate[]): Amount => {
  let amount: Amount = { currency: '', value: 0, valueDecimalPoint: 0 };

  if (rates.length) {
    if (rates.length === 1) {
      amount = rates[0].amount.origin;
    } else {
      amount = rates
        .map((rate: GuestRoomRate | EventSpaceRate): Amount => {
          return rate.amount.origin;
        })
        .reduce((acc, rateAmount) => {
          if (acc.value === 0 || (rateAmount.value && rateAmount.value < acc.value)) {
            const { currency, value, valueDecimalPoint } = rateAmount;
            acc = { currency, value, valueDecimalPoint };
          }
          return acc;
        }, amount);
    }
  }

  return amount;
};

export const getPricingInfo = (numberOfRooms: number, numberOfAttendees: number, groupRate: GroupRate) => {
  return {
    roomRate: numberOfRooms ? getMinRate(groupRate.roomRates) : { currency: '', value: 0, valueDecimalPoint: 0 },
    spaceRate: numberOfAttendees
      ? getMinRate(groupRate.eventSpaceRates)
      : { currency: '', value: 0, valueDecimalPoint: 0 },
  };
};
